
import { defineComponent } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "Room",
  setup() {
    const route = useRoute();
    const router = useRouter();

    if (!route.params.roomId) {
      router.push("/home");
    }

    return { route };
  },
});
