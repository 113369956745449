
import { defineComponent, reactive } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { UserModel } from "@/models/user.model";
import { firebaseUserService } from "@/services/firebase.service";

export default defineComponent({
  name: "Signin",
  setup() {
    const router = useRouter();
    const { loginUser } = firebaseUserService();

    const user = reactive({} as UserModel);

    const login = async () => {
      await loginUser(user);
      router.push("/home");
    };

    return {
      user,
      login,
    };
  },
});
