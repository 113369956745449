<template>
  <div style="width: 100%">
    <p>Room : {{ route.params.roomId }}</p>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "Room",
  setup() {
    const route = useRoute();
    const router = useRouter();

    if (!route.params.roomId) {
      router.push("/home");
    }

    return { route };
  },
});
</script>

<style>
</style>