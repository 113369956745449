
import { defineComponent, onMounted } from "@vue/runtime-core";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { Watcher } from "@/models/watcher.websocket";
import FileInsertion from "@/components/room/FileInsertion.vue";

const __default__ = defineComponent({
  name: "watch",
  components: {
    FileInsertion,
  },
  setup() {
    const translateScale = 100;
    const tX = ref(0);
    const tY = ref(0);
    const route = useRoute();
    const roomId = route.params.roomId as string;

    const stream = ref<MediaStream>(new MediaStream());
    const videoStream = ref();
    const socketId = ref("");
    const watcherVideo = ref<HTMLVideoElement>();
    const fileInput = ref();
    const selectedFile = ref();
    const videoWidth = ref(0);
    const videoHeight = ref(0);
    const videoDimensions = ref({
      videoWidth: 0,
      videoHeight: 0,
    });
    const scale = ref(1);
    const translateX = ref("0px");
    const translateY = ref("0px");

    const onMetadaLoad = (e: any) => {
      videoDimensions.value.videoWidth = e.target?.videoWidth;
      videoDimensions.value.videoHeight = e.target?.videoHeight;
    };

    const onVideoResize = () => {
      console.log("resize");
      const { width, height } = videoStream.value?.getBoundingClientRect();
      videoWidth.value = width;
      videoHeight.value = height;
    };

    onMounted(() => {
      const watcher = new Watcher(roomId, stream);
      watcher.socket.on("authorized", () => {
        if (watcher) {
          socketId.value = watcher.socket.id;
        }
      });
      window.addEventListener("resize", onVideoResize);
    });

    const onFileChange = () => {
      selectedFile.value = fileInput.value.files[0];
      onVideoResize();
    };

    const setFullScreen = async () => {
      await watcherVideo.value?.requestFullscreen();
    };

    const zoom = (_scale: number) => {
      window.removeEventListener("resize", onVideoResize);
      scale.value = Math.round((scale.value + _scale) * 10) / 10;
      console.log(scale.value);
      window.addEventListener("resize", onVideoResize);
    };

    const move = (x: number, y: number) => {
      tX.value += x * translateScale;
      tY.value += y * translateScale;
      translateX.value = tX.value + "px";
      translateY.value = tY.value + "px";
    };

    return {
      stream,
      videoStream,
      socketId,
      setFullScreen,
      watcherVideo,
      onFileChange,
      fileInput,
      selectedFile,
      onVideoResize,
      videoWidth,
      videoHeight,
      onMetadaLoad,
      videoDimensions,
      zoom,
      move,
      scale,
      translateX,
      translateY,
      tX,
      tY,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "76f28d27": (_ctx.scale),
  "1a46970d": (_ctx.translateX),
  "1a46970e": (_ctx.translateY)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__