import Auth from '@/components/auth/Auth.vue';
import Home from '@/components/Home.vue';
import Broadcast from '@/components/room/Broadcast.vue';
import Room from '@/components/room/Room.vue';
import RoomHome from '@/components/room/RoomHome.vue';
import Watch from '@/components/room/Watch.vue';
import Signin from '@/components/Signin.vue';
import Signup from '@/components/Signup.vue';
import { createRouter, createWebHistory } from 'vue-router';

// router.beforeEach((to, from, next) => {
//     if(to.name)
// })

const routes = [
    {
        path: '/',
        name: 'auth',
        component: Auth,
        children: [
            {
                path: '/signin',
                name: 'signin',
                component: Signin
            },
            {
                path: '/signup',
                name: 'signup',
                component: Signup
            },

        ]
    },
    {
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: '/room/:roomId',
        name: 'room',
        component: Room,
        children: [
            {
                path: 'selection',
                name: 'selection',
                component: RoomHome
            },
            {
                path:'broadcast',
                name:'broadcast',
                component: Broadcast
            },
            {
                path:'watch',
                name:'watch',
                component: Watch
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: {name: 'signin'}
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;