<template>
  <div
    class="
      container
      is-flex
      is-flex-direction-column
      is-justify-content-center
      is-align-content-center
      is-align-items-center
    "
  >
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "App",
  setup() {},
});
</script>

<style>
.container {
  height: 100%;
}
</style>
