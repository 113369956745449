
import { defineComponent, reactive, ref } from "@vue/runtime-core";
import { firebaseUserService } from "@/services/firebase.service";
import { UserModel } from "@/models/user.model";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Signup",
  setup() {
    const router = useRouter();
    const { createUser } = firebaseUserService();

    const user = reactive({} as UserModel);
    const passwordConfirm = ref("");
    const unvalidPassword = ref(false);
    const unmatchedPasswords = ref(false);
    const invalidForm = ref(true);

    const checkForm = () => {
      unvalidPassword.value = user.password?.length <= 4;
      unmatchedPasswords.value =
        !unvalidPassword.value && user.password !== passwordConfirm.value;
      invalidForm.value = user.password?.length < 4 || unmatchedPasswords.value;
    };

    const register = async () => {
      await createUser(user);
      router.push("/home");
    };

    return {
      user,
      passwordConfirm,
      unmatchedPasswords,
      unvalidPassword,
      invalidForm,
      register,
      checkForm,
    };
  },
});
