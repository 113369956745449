<template>
  <div class="canvas-content">
    <canvas ref="fileCanvas"></canvas>
  </div>
</template>

<script lang="ts">
import SVGEditor from "@/services/file-editor.service";
import { defineComponent, onMounted, PropType, watch } from "@vue/runtime-core";
import { ref, toRefs } from "vue";

export default defineComponent({
  name: "FileInsertion",
  props: {
    file: {
      type: Object as PropType<File>,
      required: true,
    },
    videoDimensions: {
      type: Object as PropType<{ videoWidth: number; videoHeight: number }>,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    scale: {
      type: Number,
      required: true,
    },
    tX: {
      type: String,
      required: true,
    },
    tY: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const fileCanvas = ref<HTMLCanvasElement>();
    let svgEditor: SVGEditor;

    const { file, width, height, videoDimensions, scale } = toRefs(props);

    const setCanvasDimensions = () => {
      console.log(
        `Width: ${width.value}, Height: ${height.value}, videoDimensions: ${videoDimensions.value.videoWidth}, ${videoDimensions.value.videoHeight}`
      );
      const widthRatio =
        (Math.round(height.value) /
          Math.round(videoDimensions.value.videoHeight)) *
        videoDimensions.value.videoWidth;

      const heightRatio =
        (Math.round(width.value) /
          Math.round(videoDimensions.value.videoWidth)) *
        videoDimensions.value.videoHeight;
      svgEditor.setDimensions({
        width: widthRatio >= width.value ? width.value : widthRatio,
        height: heightRatio >= height.value ? height.value : heightRatio,
      });
    };

    const initCanvas = async () => {
      svgEditor = new SVGEditor(fileCanvas.value as HTMLCanvasElement);
      if (file.value) {
        await svgEditor.loadImage(file.value);
        setCanvasDimensions();
      }
    };

    watch(file, initCanvas);
    watch([width, height, videoDimensions], setCanvasDimensions);
    watch(scale, () => svgEditor.scaleDimensions(scale.value));
    // watch([tX, tY], () => svgEditor.translateCanvas(tX.value, tY.value));

    onMounted(initCanvas);

    return {
      fileCanvas,
    };
  },
});
</script>

<style lang="scss" scoped>
canvas {
  position: absolute;
  width: 100%;
  transform: scale(v-bind(scale));
}

.canvas-content {
  transform: translate(v-bind(tX), v-bind(tY));
}

.canvas-controls {
  position: absolute;
  bottom: 0px;
  width: 100%;

  > .field {
    margin: 0 1rem;
  }
}

input {
  width: 80px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>