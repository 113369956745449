
import { RoomModel } from "@/models/room.model";
import { defineComponent, onMounted } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";
import { db } from "@/services/firebase.service";

export default defineComponent({
  name: "RoomHome",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const room = ref<RoomModel | null>(null);

    onMounted(() => {
      db.collection("rooms")
        .doc(route.params.roomId as string)
        .onSnapshot((obs) => {
          if (obs.exists) {
            room.value = obs.data() as RoomModel;
          } else {
            router.back();
          }
        });
    });

    const broadcastRedirect = () => {
      router.push({
        name: "broadcast",
        params: { roomId: route.params.roomId },
      });
    };

    const watchRedirect = () => {
      router.push({
        name: "watch",
        params: { roomId: route.params.roomId },
      });
    };

    return { room, broadcastRedirect, watchRedirect };
  },
});
