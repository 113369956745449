<template>
  <div class="hero">
    <h2 v-if="isStreaming">{{ socketId }}</h2>
    <div v-if="stream">
      <video :src-object.prop.camel="stream" playsinline autoplay muted>
        <track kind="captions" />
      </video>
    </div>
    <div class="mt-2 has-text-centered">
      <button v-if="!isStreaming" @click="startStreaming" class="button">
        Start Streaming
      </button>
      <button v-else @click="stopStreaming" class="button">
        Stop Streaming
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import Broadcaster from "@/models/broadcaster.websocket";
import { db } from "@/services/firebase.service";
import {
  defineComponent,
  onMounted,
  ref,
  onUnmounted,
} from "@vue/runtime-core";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Broadcast",
  setup() {
    const route = useRoute();

    const roomId = route.params.roomId as string;
    const stream = ref<MediaStream>(new MediaStream());
    const isStreaming = ref(false);
    const socketId = ref("");

    let broadcasterWS: Broadcaster | null = null;

    onMounted(async () => {
      stream.value = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
    });

    const startStreaming = () => {
      if (stream.value !== null) {
        broadcasterWS = new Broadcaster(stream, roomId);
        broadcasterWS.socket.on("authorized", () => {
          if (broadcasterWS) {
            isStreaming.value = true;
            socketId.value = broadcasterWS?.socket.id;
          }
        });
      }
    };

    const stopStreaming = async () => {
      broadcasterWS?.close();
      broadcasterWS = null;
      isStreaming.value = false;
      socketId.value = "";
    };

    onUnmounted(() => {
      db.collection("rooms").doc(roomId).update("broadcasterId", "");
    });

    return {
      stream,
      startStreaming,
      stopStreaming,
      isStreaming,
      socketId,
    };
  },
});
</script>

<style>
</style>