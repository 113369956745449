
import { db, firebaseUserService } from "@/services/firebase.service";
import {
  defineComponent,
  watch,
  ref,
  toRefs,
  onMounted,
} from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { UseRoomService } from "@/services/room.service";
import { RoomModel } from "@/models/room.model";

export default defineComponent({
  name: "Home",
  setup() {
    const roomService = UseRoomService();
    const router = useRouter();
    const { currentUser } = firebaseUserService();
    const { user } = toRefs(currentUser);

    const roomId = ref("");
    const userRoomId = ref("");

    const updateExistingRoom = async () => {
      if (user.value !== null) {
        db.collection("rooms")
          .doc(user.value.uid)
          .onSnapshot((obs) => {
            if (obs.exists) {
              const { uid } = obs.data() as RoomModel;
              userRoomId.value = uid;
            }
          });
      }
    };

    watch(user, updateExistingRoom);

    onMounted(updateExistingRoom);

    const joinRoom = async (_roomId: string) => {
      const existingRoom = await roomService.get(_roomId);
      router.push({
        name: "selection",
        params: { roomId: existingRoom.uid },
      });
    };

    const createRoom = async () => {
      const newRoom = await roomService.create();
      router.push({
        name: "selection",
        params: { roomId: newRoom.uid },
      });
    };

    const deleteRoom = async () => {
      await roomService.remove(userRoomId.value);
      userRoomId.value = "";
    };

    return {
      roomId,
      userRoomId,
      currentUser,
      joinRoom,
      createRoom,
      deleteRoom,
    };
  },
});
