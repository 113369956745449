import { RoomModel } from '@/models/room.model';
import { toRefs } from 'vue';

import { firebaseRoomService, firebaseUserService } from './firebase.service';

export const UseRoomService = () => {
    const {currentUser} = firebaseUserService();
    const {user} = toRefs(currentUser);

    const create = async () => {
        const uid = user.value?.uid
        if(!uid) throw new Error('Room creation error: no user ID');
        const newRoom = new RoomModel(uid);
        await firebaseRoomService().createRoom(newRoom);
        return newRoom;
    }

    const get = async (uid: string) => {
        const remoteRoom = await firebaseRoomService().getRoom(uid);
        return RoomModel.buildRoomModelFromRemote(remoteRoom);
    }

    const remove = (uid: string) => {
        return firebaseRoomService().deleteRoom(uid);
    }

    return {
        create,
        get,
        remove
    }
}