<template>
  <div class="box">
    <div class="field">
      <label class="label">Email</label>
      <div class="control has-icons-left has-icons-right">
        <input
          class="input"
          type="email"
          placeholder="Email"
          v-model="user.email"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-envelope"></i>
        </span>
      </div>
    </div>

    <div class="field">
      <label class="label">Password</label>
      <p class="control has-icons-left">
        <input
          class="input"
          type="password"
          placeholder="Password"
          v-model="user.password"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-lock"></i>
        </span>
      </p>
    </div>

    <div class="field mt-4 is-grouped is-grouped-centered">
      <div class="control">
        <button
          @click="login"
          :disabled="!(user.email?.length > 0 && user.password?.length > 0)"
          class="button is-link"
        >
          Login
        </button>
      </div>
    </div>
    <div class="has-text-centered">
      <p>Not registered yet - <router-link to="/">sign up ?</router-link></p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { UserModel } from "@/models/user.model";
import { firebaseUserService } from "@/services/firebase.service";

export default defineComponent({
  name: "Signin",
  setup() {
    const router = useRouter();
    const { loginUser } = firebaseUserService();

    const user = reactive({} as UserModel);

    const login = async () => {
      await loginUser(user);
      router.push("/home");
    };

    return {
      user,
      login,
    };
  },
});
</script>

<style>
</style>