import { firebaseUserService } from '@/services/firebase.service';
import { boundMethod } from 'autobind-decorator';
import { io, Socket } from 'socket.io-client';
import { Ref } from 'vue';

export default class Broadcaster {

    socket: Socket;
    peerConnectionsMap: Map<number, RTCPeerConnection> = new Map();

    constructor (private cameraMediaStream: Ref<MediaStream>, private userUid: string) {
        const socketHost = (window.location.hostname === 'localhost') ? 'ws://localhost:3000' : process.env.VUE_APP_WS_SERVER_HOST;
        this.socket = io(socketHost);
        this.attachEvents();
    }

    attachEvents() {
        this.socket.on("watcher", this.onNewWatcher);
        this.socket.on("answer", this.onWatcherAnswer);
        this.socket.on("connect", () => {
            firebaseUserService().currentUser.user?.getIdToken()
            .then(bearer => {
                this.socket.emit('authorization', bearer)
            })
        });
        this.socket.on("authorized", () => this.socket.emit("broadcaster", this.userUid));
        this.socket.on("candidate", this.watcherCandidate);
        this.socket.on("disconnectPeer", this.watcherDisconnect);
    }

    close() {
        this.peerConnectionsMap.forEach(peerConnection => {
            peerConnection.close();
        });
        this.socket.close();
    }

    @boundMethod
    async onNewWatcher(newWatcherId: number) {
        const newPeerConnection = new RTCPeerConnection({
            iceServers: [{ urls: ["stun:stun1.l.google.com:19302"] }]
        })

        // ICE CANDIDATE EVENT MUST ALWAYS BE RIGHT AFTER THE CONNECTION CREATION
        newPeerConnection.onicecandidate = event => {
            if (event.candidate) {
                this.socket.emit("candidate", newWatcherId, event.candidate);
            }
        }

        this.peerConnectionsMap.set(newWatcherId, newPeerConnection);
        this.cameraMediaStream.value.getTracks().forEach(track => {
            newPeerConnection.addTrack(track, this.cameraMediaStream.value)
        });

        const sessionLocalDescription = await newPeerConnection.createOffer();
        await newPeerConnection.setLocalDescription(sessionLocalDescription);
        this.socket.emit("offer", newWatcherId, newPeerConnection.localDescription);
    }

    @boundMethod
    onWatcherAnswer(watcherId: number, description: RTCSessionDescriptionInit) {
        const peerConnection = this.peerConnectionsMap.get(watcherId)
        if(peerConnection) {
            peerConnection.setRemoteDescription(description);
        } else {
            console.log('no peer connection for the given id: ' + watcherId);
        }
    }

    @boundMethod
    watcherCandidate(watcherId: number, candidate: RTCIceCandidate | RTCIceCandidateInit) {
        const peerConnection = this.peerConnectionsMap.get(watcherId)
        if(peerConnection) {
            peerConnection.addIceCandidate(candidate);
        } else {
            console.log('no peer connection for the given id: ' + watcherId);
        }
    }

    @boundMethod
    watcherDisconnect(watcherId: number) {
        this.peerConnectionsMap.get(watcherId)?.close();
        this.peerConnectionsMap.delete(watcherId);
    }
}