import 'firebase/database';

import { RoomModel } from '@/models/room.model';
import { UserModel } from '@/models/user.model';
import firebase from 'firebase';
import { reactive } from 'vue';

import { displayMessage } from './toaster.service';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: "wallander-app.firebaseapp.com",
    databaseURL: "https://wallander-app.firebaseio.com",
    projectId: "wallander-app",
    storageBucket: "wallander-app.appspot.com",
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
}

const firebaseApp = firebase.initializeApp(firebaseConfig);

const currentUser = reactive<{loading: boolean, user: firebase.User | null}>({
    loading: true,
    user: null
});

firebase.auth().onAuthStateChanged((user) => {
    currentUser.loading = false
    if(user) {
        currentUser.user = user;
    } else {
        currentUser.user = null
    }
})

const firebaseUserService = () => {
    
    const createUser = ({email, password}: UserModel) => {
        try {
            return firebase.auth().createUserWithEmailAndPassword(email, password);
        } catch(error) {
            displayMessage(`Registration error: ${error}`);
            throw new Error('Registration error');
        }
    }
    
    const loginUser = ({email, password}: UserModel) => {
        try {
            return firebase.auth().signInWithEmailAndPassword(email, password);
        } catch(error) {
            displayMessage(`Login error : ${error}`);
            throw new Error('Login error');
        }
    }
    
    const logoutUser = async () => {
        return firebase.auth().signOut();
    }

    return {createUser, loginUser, logoutUser, currentUser}
}

const firebaseRoomService = () => {

    const createRoom = (room: RoomModel) => {
        return db.collection("rooms").doc(room.uid).set(Object.assign({}, room));
    }

    const getRoom = async (uid: string) => {
        const doc = await db.collection("rooms").doc(uid).get();
        if(doc.exists) {
            const room = doc.data();
            if(room) return room as RoomModel;
        }
        throw new Error(`No room exixsts in firestore for the UID : ${uid}`);
    }

    const deleteRoom = (uid: string) => {
        return db.collection("rooms").doc(uid).delete();
    }

    return {
        createRoom,
        getRoom,
        deleteRoom
    }
}


export const db = firebaseApp.firestore();
export {firebaseUserService, firebaseRoomService};