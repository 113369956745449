<template>
  <div class="box">
    <p>Choose :</p>
    <div v-if="room">
      <button
        @click="broadcastRedirect"
        :disabled="!!room.broadcasterId"
        class="m-2 button button is-link"
      >
        Boradcast
      </button>
      <button @click="watchRedirect" class="m-2 button button is-link">
        watch
      </button>
    </div>
    <div v-else>loading...</div>
  </div>
</template>

<script lang="ts">
import { RoomModel } from "@/models/room.model";
import { defineComponent, onMounted } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";
import { db } from "@/services/firebase.service";

export default defineComponent({
  name: "RoomHome",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const room = ref<RoomModel | null>(null);

    onMounted(() => {
      db.collection("rooms")
        .doc(route.params.roomId as string)
        .onSnapshot((obs) => {
          if (obs.exists) {
            room.value = obs.data() as RoomModel;
          } else {
            router.back();
          }
        });
    });

    const broadcastRedirect = () => {
      router.push({
        name: "broadcast",
        params: { roomId: route.params.roomId },
      });
    };

    const watchRedirect = () => {
      router.push({
        name: "watch",
        params: { roomId: route.params.roomId },
      });
    };

    return { room, broadcastRedirect, watchRedirect };
  },
});
</script>

<style>
</style>