<template>
  <div class="box">
    <div class="field">
      <label class="label">Email</label>
      <div class="control has-icons-left has-icons-right">
        <input
          class="input"
          type="email"
          placeholder="Email"
          v-model="user.email"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-envelope"></i>
        </span>
      </div>
    </div>

    <div class="field">
      <label class="label">Password</label>
      <p class="control has-icons-left">
        <input
          class="input"
          type="password"
          placeholder="Password"
          v-model="user.password"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-lock"></i>
        </span>
      </p>
    </div>

    <div class="field">
      <label class="label">Confirm Password</label>
      <p class="control has-icons-left">
        <input
          class="input"
          type="password"
          placeholder="Confirm Password"
          v-model="passwordConfirm"
          @blur="checkForm()"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-lock"></i>
        </span>
      </p>
      <p v-if="unvalidPassword" class="help is-danger">
        Invalid Password (must be > 4)
      </p>
      <p v-if="unmatchedPasswords" class="help is-danger">
        The passwords doesn't matches
      </p>
    </div>

    <div class="field mt-4 is-grouped is-grouped-centered">
      <div class="control">
        <button
          @click="register"
          :disabled="invalidForm"
          class="button is-link"
        >
          Submit
        </button>
      </div>
    </div>
    <div class="has-text-centered">
      <p>
        Already registered - <router-link to="/login">sign in ?</router-link>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "@vue/runtime-core";
import { firebaseUserService } from "@/services/firebase.service";
import { UserModel } from "@/models/user.model";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Signup",
  setup() {
    const router = useRouter();
    const { createUser } = firebaseUserService();

    const user = reactive({} as UserModel);
    const passwordConfirm = ref("");
    const unvalidPassword = ref(false);
    const unmatchedPasswords = ref(false);
    const invalidForm = ref(true);

    const checkForm = () => {
      unvalidPassword.value = user.password?.length <= 4;
      unmatchedPasswords.value =
        !unvalidPassword.value && user.password !== passwordConfirm.value;
      invalidForm.value = user.password?.length < 4 || unmatchedPasswords.value;
    };

    const register = async () => {
      await createUser(user);
      router.push("/home");
    };

    return {
      user,
      passwordConfirm,
      unmatchedPasswords,
      unvalidPassword,
      invalidForm,
      register,
      checkForm,
    };
  },
});
</script>

<style>
.box {
  max-width: 500px;
}
</style>