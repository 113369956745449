export class RoomModel {

    public static buildRoomModelFromRemote({
        uid,
        created,
        broadcasterId,
        listWatchersId
    }: RoomModel) {
        const room = new RoomModel(uid, created);
        room.broadcasterId = broadcasterId;
        room.listWatchersId = listWatchersId;
        return room;
    }

     
    created: number;
    broadcasterId: string | null = null;
    listWatchersId: string[] = [];

    constructor(public uid: string, date = Date.now()) {
        this.created = date;
    }
}