<template>
  <div class="box">
    <p class="my-3 has-text-centered">Choose :</p>
    <div class="field has-addons">
      <div class="control">
        <input
          v-model="roomId"
          class="input"
          type="text"
          placeholder="Room ID"
        />
      </div>
      <div class="control">
        <a @click="joinRoom(roomId)" class="button is-info"> Join a Room </a>
      </div>
    </div>
    <div class="divider">OR</div>
    <div class="has-text-centered">
      <button
        v-show="userRoomId"
        @click="joinRoom(userRoomId)"
        class="m-2 button button is-link"
      >
        Join your room
      </button>
      <button
        v-if="!userRoomId"
        @click="createRoom"
        class="m-2 button button is-link"
      >
        Create a new Room
      </button>
      <button v-else @click="deleteRoom" class="m-2 button button is-danger">
        Delete your room
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { db, firebaseUserService } from "@/services/firebase.service";
import {
  defineComponent,
  watch,
  ref,
  toRefs,
  onMounted,
} from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { UseRoomService } from "@/services/room.service";
import { RoomModel } from "@/models/room.model";

export default defineComponent({
  name: "Home",
  setup() {
    const roomService = UseRoomService();
    const router = useRouter();
    const { currentUser } = firebaseUserService();
    const { user } = toRefs(currentUser);

    const roomId = ref("");
    const userRoomId = ref("");

    const updateExistingRoom = async () => {
      if (user.value !== null) {
        db.collection("rooms")
          .doc(user.value.uid)
          .onSnapshot((obs) => {
            if (obs.exists) {
              const { uid } = obs.data() as RoomModel;
              userRoomId.value = uid;
            }
          });
      }
    };

    watch(user, updateExistingRoom);

    onMounted(updateExistingRoom);

    const joinRoom = async (_roomId: string) => {
      const existingRoom = await roomService.get(_roomId);
      router.push({
        name: "selection",
        params: { roomId: existingRoom.uid },
      });
    };

    const createRoom = async () => {
      const newRoom = await roomService.create();
      router.push({
        name: "selection",
        params: { roomId: newRoom.uid },
      });
    };

    const deleteRoom = async () => {
      await roomService.remove(userRoomId.value);
      userRoomId.value = "";
    };

    return {
      roomId,
      userRoomId,
      currentUser,
      joinRoom,
      createRoom,
      deleteRoom,
    };
  },
});
</script>

<style>
</style>