<template>
  <div v-if="!loading">
    <router-view></router-view>
  </div>
  <div v-else>loading user...</div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";
import { firebaseUserService } from "@/services/firebase.service";
import { useRouter } from "vue-router";
import { toRefs, watch } from "vue";

export default defineComponent({
  setup() {
    const router = useRouter();
    const { currentUser } = firebaseUserService();
    const { user, loading } = toRefs(currentUser);

    watch(user, () => {
      if (user.value != null) {
        router.push("/home");
      }
    });

    return {
      loading,
    };
  },
});
</script>

<style>
</style>