<template>
  <div class="watcher-container">
    <div class="has-text-centered">
      <h2 v-if="socketId">{{ socketId }}</h2>
    </div>
    <div id="watcherVideo" ref="watcherVideo">
      <video
        ref="videoStream"
        :src-object.prop.camel="stream"
        @loadedmetadata="onMetadaLoad"
        @resize="onVideoResize()"
        playsinline
        autoplay
        muted
      >
        <track kind="captions" />
      </video>
      <fileInsertion
        v-if="selectedFile"
        :file="selectedFile"
        :width="videoWidth"
        :height="videoHeight"
        :videoDimensions="videoDimensions"
        :scale="scale"
        :tX="translateX"
        :tY="translateY"
      />
      <div class="controls mt-2 has-text-centered">
        <button class="button" @click="zoom(0.1)">+</button>
        <button class="button" @click="zoom(-0.1)">-</button>
        <button class="button" @click="move(-0.1, 0)">&larr;</button>
        <button class="button" @click="move(0.1, 0)">&rarr;</button>
        <button class="button" @click="move(0, -0.1)">&uarr;</button>
        <button class="button" @click="move(0, 0.1)">&darr;</button>
        <button class="button" @click="setFullScreen">FullScreen</button>
        <input
          ref="fileInput"
          type="file"
          class="button"
          @change="onFileChange($event)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "@vue/runtime-core";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { Watcher } from "@/models/watcher.websocket";
import FileInsertion from "@/components/room/FileInsertion.vue";

export default defineComponent({
  name: "watch",
  components: {
    FileInsertion,
  },
  setup() {
    const translateScale = 100;
    const tX = ref(0);
    const tY = ref(0);
    const route = useRoute();
    const roomId = route.params.roomId as string;

    const stream = ref<MediaStream>(new MediaStream());
    const videoStream = ref();
    const socketId = ref("");
    const watcherVideo = ref<HTMLVideoElement>();
    const fileInput = ref();
    const selectedFile = ref();
    const videoWidth = ref(0);
    const videoHeight = ref(0);
    const videoDimensions = ref({
      videoWidth: 0,
      videoHeight: 0,
    });
    const scale = ref(1);
    const translateX = ref("0px");
    const translateY = ref("0px");

    const onMetadaLoad = (e: any) => {
      videoDimensions.value.videoWidth = e.target?.videoWidth;
      videoDimensions.value.videoHeight = e.target?.videoHeight;
    };

    const onVideoResize = () => {
      console.log("resize");
      const { width, height } = videoStream.value?.getBoundingClientRect();
      videoWidth.value = width;
      videoHeight.value = height;
    };

    onMounted(() => {
      const watcher = new Watcher(roomId, stream);
      watcher.socket.on("authorized", () => {
        if (watcher) {
          socketId.value = watcher.socket.id;
        }
      });
      window.addEventListener("resize", onVideoResize);
    });

    const onFileChange = () => {
      selectedFile.value = fileInput.value.files[0];
      onVideoResize();
    };

    const setFullScreen = async () => {
      await watcherVideo.value?.requestFullscreen();
    };

    const zoom = (_scale: number) => {
      window.removeEventListener("resize", onVideoResize);
      scale.value = Math.round((scale.value + _scale) * 10) / 10;
      console.log(scale.value);
      window.addEventListener("resize", onVideoResize);
    };

    const move = (x: number, y: number) => {
      tX.value += x * translateScale;
      tY.value += y * translateScale;
      translateX.value = tX.value + "px";
      translateY.value = tY.value + "px";
    };

    return {
      stream,
      videoStream,
      socketId,
      setFullScreen,
      watcherVideo,
      onFileChange,
      fileInput,
      selectedFile,
      onVideoResize,
      videoWidth,
      videoHeight,
      onMetadaLoad,
      videoDimensions,
      zoom,
      move,
      scale,
      translateX,
      translateY,
      tX,
      tY,
    };
  },
});
</script>

<style lang="scss" scoped>
.watcher-container {
  width: 100%;
}

#watcherVideo {
  position: relative;
  height: 80vh;
  display: flex;
  justify-content: center;
  > * {
    position: absolute;
  }
}

video {
  position: absolute;
  width: 100%;
  max-height: 100%;
  transform: scale(v-bind(scale))
    translate(v-bind(translateX), v-bind(translateY));
}

.controls {
  bottom: 2rem;
}
</style>