import { firebaseUserService } from '@/services/firebase.service';
import { boundMethod } from 'autobind-decorator';
import { io, Socket } from 'socket.io-client';
import { Ref } from 'vue';

export class Watcher {

    socket: Socket;
    peerConnection?: RTCPeerConnection;
    stream: Ref<MediaStream | null>;

    constructor (private userUid: string, refStream: Ref<MediaStream>) {
        const socketHost = (window.location.hostname === 'localhost') ? 'ws://localhost:3000' : process.env.VUE_APP_WS_SERVER_HOST;
        this.socket = io(socketHost);
        this.attachEvents();
        this.stream = refStream;
        
    }

    attachEvents() {
        this.socket.on("offer", this.onNewAnswer);
        this.socket.on("candidate", this.onBroadcastCandidate);
        this.socket.on("connect", () => {
            firebaseUserService().currentUser.user?.getIdToken()
            .then(bearer => {
                this.socket.emit('authorization', bearer)
            })
        });
        this.socket.on('authorized', () => this.socket.emit("watcher", this.userUid));
        this.socket.on("broadcaster", () => this.socket.emit("watcher", this.userUid));
        this.socket.on("disconnectPeer", () => this.peerConnection?.close());
    }

    close() {
        this.peerConnection?.close();
        this.socket.close();
    }

    @boundMethod
    async onNewAnswer(broadcastId: number, description: RTCSessionDescriptionInit) {
        this.peerConnection = new RTCPeerConnection({
            iceServers: [{ urls: ["stun:stun1.l.google.com:19302"] }]
        });

        // ICE CANDIDATE EVENT MUST ALWAYS BE RIGHT AFTER THE CONNECTION CREATION
        this.peerConnection.onicecandidate = event => {
            if (event.candidate) {
                this.socket.emit("candidate", broadcastId, event.candidate);
            }
        }
        this.peerConnection.addEventListener("track", (event: RTCTrackEvent) => {
            event.streams[0].getTracks().forEach((track) => {
                this.stream.value?.addTrack(track)
            });
        });
        
        await this.peerConnection.setRemoteDescription(description)
        const sessionInitDescription = await this.peerConnection.createAnswer();
        await this.peerConnection.setLocalDescription(sessionInitDescription);
        this.socket.emit("answer", broadcastId, this.peerConnection.localDescription);

    }

    @boundMethod
    onBroadcastCandidate(broadcastId: number, candidate: RTCIceCandidate | RTCIceCandidateInit) {
        this.peerConnection?.addIceCandidate(candidate)
            .catch(error => console.error(error));
    }
}