import { fabric } from 'fabric';

export default class SVGEditor {

    private canvas: fabric.Canvas;
    private objects: fabric.Object[];
    private svgGroupe: fabric.Group | fabric.Object;
    private actualDimensions: fabric.ICanvasDimensions
    private lineStroke = 1;

    constructor (canvas: HTMLCanvasElement) {
        this.canvas = new fabric.Canvas(canvas);
    }

    scaleDimensions(scale: number) {
        this.canvas.height = (this.canvas.height as number) * scale;
        this.canvas.width = (this.canvas.width as number) * scale
    }

    translateCanvas(x:number, y: number) {
        const [pos] = this.svgGroupe.getCoords();
        pos.setX(pos.x + x);
        pos.setY(pos.y + y);
    }

    setDimensions(newDimension: fabric.ICanvasDimensions) {
        console.log(newDimension);

        // Position of object in group
        const [pos] = this.svgGroupe.getCoords();
        const xFactor = (pos.x / this.canvas.getWidth());
        const yFactor = (pos.y / this.canvas.getHeight());
        pos.setX(xFactor * Number(newDimension.width));
        pos.setY(yFactor * Number(newDimension.height));

        // Scale of object in group
        if(xFactor !== 0 && yFactor !== 0 && this.actualDimensions && this.svgGroupe.scaleY && this.svgGroupe.scaleX) {
            const xScaleFactor = Number(newDimension.width) / Number(this.actualDimensions.width);
            const yScaleFactor = Number(newDimension.height) / Number(this.actualDimensions.height);
            this.svgGroupe.scaleY = (yScaleFactor * this.svgGroupe.scaleY);
            this.svgGroupe.scaleX = (xScaleFactor * this.svgGroupe.scaleX);
        }
       
        this.svgGroupe.setPositionByOrigin(pos, 'left', 'top');
        this.canvas.clear();
        this.canvas.setDimensions(newDimension);
        this.canvas.add(this.svgGroupe);
        this.canvas.renderAll();
        this.actualDimensions = newDimension;
    }

    loadImage(selectedFile: File): Promise<void> {
        return new Promise((res, rej) => {
            try {
                fabric.loadSVGFromURL(URL.createObjectURL(selectedFile), (objects: fabric.Object[], options) => {
                    this.canvas.clear();
                    this.objects = objects;
                    objects.forEach(obj => {
                        obj.set({
                            stroke: 'black',
                            fill: 'rgba(0,0,0,0)',
                            strokeUniform : true,
                        })
                    })
                    this.svgGroupe = fabric.util.groupSVGElements(objects, options);
                    this.svgGroupe.scaleToWidth(100);
                    this.svgGroupe.scaleToHeight(100);
                    this.canvas.add(this.svgGroupe);
                    res();
                });
            } catch(e) {
                rej(e)
            }
        
        })
        
    }
}